import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, withModifiers as _withModifiers, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "wallet-top-up-header" }
const _hoisted_2 = { class: "wallet-top-up-header-heading" }
const _hoisted_3 = { class: "wallet-top-up-header-subheading" }
const _hoisted_4 = { class: "qrCode" }
const _hoisted_5 = ["src", "alt"]
const _hoisted_6 = { class: "currency-network" }
const _hoisted_7 = { class: "wallet-top-up-address" }
const _hoisted_8 = { class: "wallet-top-up-address-text" }
const _hoisted_9 = { class: "text-xs text-topup-wallet" }
const _hoisted_10 = ["src"]
const _hoisted_11 = {
  key: 0,
  class: "wallet-top-up-tag"
}
const _hoisted_12 = { class: "text-xs brand-grey-500" }
const _hoisted_13 = { class: "text-xs" }
const _hoisted_14 = ["src"]
const _hoisted_15 = { class: "wallet-top-up-info" }
const _hoisted_16 = ["src"]
const _hoisted_17 = { class: "ml-2" }
const _hoisted_18 = { class: "font-bold" }
const _hoisted_19 = { class: "text-xs mt-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_DropDownList = _resolveComponent("DropDownList")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createBlock(_component_Dialog, {
    open: _ctx.showDialog,
    width: 416,
    onCloseDialog: _cache[4] || (_cache[4] = ($event: any) => (_ctx.$emit('hideDialog')))
  }, {
    content: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t('balance.dialog.headingText')), 1),
        _createElementVNode("div", _hoisted_3, " Only send " + _toDisplayString(_ctx.selectedTopupCoin) + " ( " + _toDisplayString(_ctx.selectedTopupNetwork) + " ) to this wallet using its native blockchain to top up your balance ", 1),
        _createElementVNode("div", _hoisted_4, [
          (_ctx.walletAddress)
            ? (_openBlock(), _createElementBlock("img", {
                key: 0,
                src: _ctx.qrCodeUrl,
                alt: _ctx.walletAddress,
                height: "180",
                width: "185"
              }, null, 8, _hoisted_5))
            : _createCommentVNode("", true)
        ])
      ]),
      _createElementVNode("div", _hoisted_6, [
        (_openBlock(), _createBlock(_component_Dropdown, {
          options: _ctx.coinList,
          "onUpdate:selected": _ctx.selectTopupCoin,
          "default-value": {
            label: _ctx.selectedTopupCoin,
            icon: _ctx.selectedTopupCoin,
            value: _ctx.selectedTopupCoin,
          },
          key: _ctx.selectedTopupCoin
        }, null, 8, ["options", "onUpdate:selected", "default-value"])),
        (_openBlock(), _createBlock(_component_DropDownList, {
          options: _ctx.networksList,
          "onUpdate:selected": _ctx.selectTopupNetwork,
          "default-value": _ctx.selectedTopupNetwork,
          key: _ctx.selectedTopupNetwork
        }, null, 8, ["options", "onUpdate:selected", "default-value"]))
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("div", null, [
          _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.$t('balance.dialog.walletAddress')), 1),
          _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.loading ? 'Loading...' : _ctx.walletAddress ?? 'Address not found'), 1)
        ]),
        _createElementVNode("div", null, [
          _createElementVNode("img", {
            src: require('@/assets/Copy.svg'),
            alt: "Copy",
            class: "cursor-pointer",
            onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.copy()), ["prevent"])),
            onKeypress: _cache[1] || (_cache[1] = ($event: any) => (_ctx.copy()))
          }, null, 40, _hoisted_10)
        ])
      ]),
      (_ctx.addressTag)
        ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
            _createElementVNode("div", null, [
              _createElementVNode("div", _hoisted_12, _toDisplayString(_ctx.$t('balance.dialog.tag')), 1),
              _createElementVNode("div", _hoisted_13, _toDisplayString(_ctx.addressTag), 1)
            ]),
            _createElementVNode("div", null, [
              _createElementVNode("img", {
                src: require('@/assets/Copy.svg'),
                alt: "Copy",
                class: "cursor-pointer",
                onClick: _cache[2] || (_cache[2] = _withModifiers(($event: any) => (_ctx.copyTag()), ["prevent"])),
                onKeypress: _cache[3] || (_cache[3] = ($event: any) => (_ctx.copyTag()))
              }, null, 40, _hoisted_14)
            ])
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_15, [
        _createElementVNode("div", null, [
          _createElementVNode("img", {
            src: require('@/assets/Warning.svg'),
            alt: "Warning"
          }, null, 8, _hoisted_16)
        ]),
        _createElementVNode("div", _hoisted_17, [
          _createElementVNode("div", _hoisted_18, " Only send " + _toDisplayString(_ctx.selectedTopupCoin) + " ( " + _toDisplayString(_ctx.selectedTopupNetwork) + " ) to this address ", 1),
          _createElementVNode("div", _hoisted_19, " Sending any other digital asset through the " + _toDisplayString(_ctx.selectedTopupCoin) + " ( " + _toDisplayString(_ctx.selectedTopupNetwork) + " ) will result in permanent loss ", 1)
        ])
      ])
    ]),
    _: 1
  }, 8, ["open"]))
}
import { defineStore, storeToRefs } from 'pinia';
import { SearchTypes } from '@/types/searchService';
import {
  handleQueryConstruction,
  determineSearchType,
} from '@/utils/searchService';
import { reactive, ref } from 'vue';
import {
  prepareTransactionData,
  prepareMatchQueryParameter,
} from '@/utils/tables';
import { TransactionTypes } from '@/types/tables/transactions';
import PortalService from '@/common/services/portal.service';
import { SettlementTypes } from '@/types/tables/settlements';
import { CollectionTypes } from '@/types/tables/collections';
import { PaymentTypes } from '@/types/tables/payments';
import { FeeTypes } from '@/types/tables/fees';
import { usePaymentsStore } from '@/store/modules/payments';
import { useCollectionsStore } from '@/store/modules/collections';
import { useSettlementsStore } from '@/store/modules/settlements';
import { useFeesStore } from '@/store/modules/fees';
import { useAuthStore } from './auth';

const paymentsIndexName = process.env.VUE_APP_PAYMENT_INDEX;
const collectionIndexName = process.env.VUE_APP_COLLECTION_INDEX;
const settlementIndexName = process.env.VUE_APP_SETTLEMENT_INDEX;
const feesIndexName = process.env.VUE_APP_FEES_INDEX;
export const useTransactionsStore = defineStore('transactions', () => {
  const authStore = useAuthStore();
  const paymentsStore = usePaymentsStore();
  const collectionsStore = useCollectionsStore();
  const settlementStore = useSettlementsStore();
  const feesStore = useFeesStore();
  const { totalPayments, paymentFilters } = storeToRefs(paymentsStore);
  const { totalCollections, collectionFilters } = storeToRefs(collectionsStore);
  const { totalSettlements, settlementFilters } = storeToRefs(settlementStore);
  const { totalFees, feesFilters } = storeToRefs(feesStore);
  const { user } = storeToRefs(authStore);
  const allTransactionData = reactive<any[]>([]);
  const displayData = reactive<any[]>([]);
  const transactionsQuery = reactive<TransactionTypes.FilterQuery>({
    startIndex: 0,
    idFilter: '',
    range: {
      startDate: '',
      endDate: '',
    },
  });
  const isLoading = ref(false);
  const noDataForId = ref(false);
  // const paymentsCount = reactive<number[]>([]);
  // const collectionsCount = reactive<number[]>([]);
  // const paymentsIndex = ref(0);
  // const collectionsIndex = ref(0);
  const navigatingForward = ref(true);
  const noTransactionData = ref(false);
  const fetchingAllTransactionData = ref(false);
  const displayIndex = ref(0);
  const displayCount = ref(0);
  const noDataForFilter = ref(false);
  const noTransactionDataForDateFilter = ref(false);
  const filterTransactions = reactive<TransactionTypes.FilterCategory[]>([
    {
      name: TransactionTypes.Filters.COUNTRY,
      options: [],
      filter: '',
    },
    {
      name: TransactionTypes.Filters.STATUS,
      options: [],
      filter: '',
    },
    {
      name: TransactionTypes.Filters.PAYMENT_METHOD,
      options: [],
      filter: '',
    },
    {
      name: TransactionTypes.Filters.TRANSACTION_TYPE,
      options: [],
      filter: '',
    },
    {
      name: TransactionTypes.Filters.REQUEST_SOURCE,
      options: [],
      filter: '',
    },
  ]);
  const displayHeaders = reactive<string[]>([]);

  function resetTransactionsStores() {
    allTransactionData.splice(0);
    transactionsQuery.startIndex = 0;
    transactionsQuery.idFilter = '';
    transactionsQuery.range.startDate = '';
    transactionsQuery.range.endDate = '';

    paymentsStore.resetPaymentStore();
    collectionsStore.resetCollectionStore();
    settlementStore.resetSettlementStore();
    feesStore.resetFeesStore();

    noTransactionData.value = false;
    noDataForId.value = false;
    displayIndex.value = 0;
    displayData.splice(0);
    noDataForFilter.value = false;
  }

  function setAllTransactionData(data: any[]) {
    allTransactionData.splice(0);
    data.forEach(item => allTransactionData.push(item));
  }

  function setDisplayHeaders(data: any[]) {
    displayHeaders.splice(0);
    data.forEach(item => displayHeaders.push(item));
  }

  function setFilters(data: TransactionTypes.FilterCategory[]) {
    filterTransactions.splice(0);
    data.forEach(item => filterTransactions.push(item));
  }

  function setDisplayData(data: any[]) {
    displayData.splice(0);
    data.forEach(item => displayData.push(item));
  }

  function setQueryStartIndex(newIndex: number) {
    navigatingForward.value = newIndex > transactionsQuery.startIndex;
    transactionsQuery.startIndex = newIndex;
  }

  function setQueryIdSearchFilter(searchValue: string) {
    transactionsQuery.idFilter = searchValue;
  }

  function setStartAndEndDateSearchFilter(startDate: string, endDate: string) {
    transactionsQuery.range.startDate = startDate;
    transactionsQuery.range.endDate = endDate;
  }

  function tabChange() {
    switch (displayIndex.value) {
      case TransactionTypes.DisplayTable.PAYMENTS:
        setFilters(paymentFilters.value);
        break;
      case TransactionTypes.DisplayTable.COLLECTIONS:
        setFilters(collectionFilters.value);
        break;
      case TransactionTypes.DisplayTable.SETTLEMENTS:
        setFilters(settlementFilters.value);
        break;
      case TransactionTypes.DisplayTable.FEES:
        setFilters(feesFilters.value);
        break;
      default:
        setFilters(paymentFilters.value);
        break;
    }
  }

  function resetFilterSelection() {
    filterTransactions.splice(0);
    tabChange();
  }

  async function getPartnerTransactions(
    request: SearchTypes.QueryRequest,
    token: string,
    tableName: string,
  ) {
    try {
      const query = handleQueryConstruction(request, tableName);
      return await PortalService.GetData(token, query);
    } catch (e: any) {
      console.error(e.message);
      throw new Error('Could not retrieve partner transactions');
    }
  }

  function addToTabFilters(data: any[]) {
    switch (displayIndex.value) {
      case TransactionTypes.DisplayTable.PAYMENTS:
        paymentsStore.preparePaymentFilters(data);
        setFilters(paymentFilters.value);
        break;
      case TransactionTypes.DisplayTable.COLLECTIONS:
        collectionsStore.prepareCollectionFilters(data);
        setFilters(collectionFilters.value);
        break;
      case TransactionTypes.DisplayTable.SETTLEMENTS:
        settlementStore.prepareSettlementFilters(data);
        setFilters(settlementFilters.value);
        break;
      case TransactionTypes.DisplayTable.FEES:
        feesStore.prepareFeesFilters(data);
        setFilters(feesFilters.value);
        break;
      default:
        paymentsStore.preparePaymentFilters(data);
        setFilters(paymentFilters.value);
        break;
    }
  }

  async function getAllDataScroll(
    partnerId: string,
    token: string,
    indexName: string,
    table: TransactionTypes.TransactionTable,
    limit: number,
  ): Promise<any[]> {
    const records: unknown[] = [];
    try {
      const query = {
        query: {
          bool: {
            should: [],
            must: prepareMatchQueryParameter(
              partnerId,
              filterTransactions,
              displayIndex.value,
            ),
            filter: determineSearchType(transactionsQuery.idFilter),
          },
        },
        from: 0,
        sort: [
          {
            createdAt: 'desc',
          },
        ],
      };
      const getAllDataResponse = await PortalService.GetAllData(token, {
        query,
        index: indexName,
        scroll_time: '2m',
        scroll_size: 2500,
      });
      let id = getAllDataResponse.scroll_id;
      let { hits } = getAllDataResponse;

      while (hits && hits.length && records.length < limit) {
        const cleanedData = prepareTransactionData(hits, table, true);
        addToTabFilters(cleanedData);
        records.push(...cleanedData);
        const resp = await PortalService.GetAllData(token, {
          query,
          index: indexName,
          scroll_time: '2m',
          scroll_size: 2500,
          scroll_id: id,
        });
        id = resp.scroll_id;
        hits = resp.hits;
      }
      return records;
    } catch (e: any) {
      throw new Error('encountered error retrieving all data');
    }
  }

  async function getAllData(
    indexName: string,
    totalCount: number,
    table: TransactionTypes.TransactionTable,
    limit: number,
  ): Promise<any[]> {
    // the most records that can be retrieved at a time is 6000, if the counts exceed that then data needs to be fetched in batches
    const records: unknown[] = [];
    if (!user.value || !user.value.partnerId || !user.value.token) {
      throw new Error('cannot retrieve data without user, partnerId and token');
    }
    try {
      fetchingAllTransactionData.value = true;
      const { partnerId } = user.value;
      if (totalCount > 6000) {
        const allData = await getAllDataScroll(
          partnerId,
          user.value.token,
          indexName,
          table,
          limit,
        );
        records.push(...allData);
      } else {
        // can just fetch all the data in one go
        const rawData = await getPartnerTransactions(
          {
            startIndex: 0,
            size: totalCount,
            sort: [{ createdAt: 'desc' }],
            should: [],
            must: prepareMatchQueryParameter(
              partnerId,
              filterTransactions,
              displayIndex.value,
            ),
            filter: transactionsQuery,
          },
          user.value.token,
          indexName,
        );
        const cleanedData = prepareTransactionData(rawData.data, table);
        addToTabFilters(cleanedData);
        records.push(...cleanedData);
      }

      fetchingAllTransactionData.value = false;
      return records;
    } catch (e) {
      fetchingAllTransactionData.value = false;
      throw new Error('encountered error retrieving all data');
    }
  }

  async function getAllTransactionData() {
    if (!fetchingAllTransactionData.value) {
      let allData: any[];
      switch (displayIndex.value) {
        case TransactionTypes.DisplayTable.PAYMENTS:
          allData = await getAllData(
            `${paymentsIndexName}`,
            totalPayments.value,
            TransactionTypes.TransactionTable.PAYMENTS,
            10000,
          );
          break;
        case TransactionTypes.DisplayTable.COLLECTIONS:
          allData = await getAllData(
            `${collectionIndexName}`,
            totalCollections.value,
            TransactionTypes.TransactionTable.COLLECTIONS,
            10000,
          );
          break;
        case TransactionTypes.DisplayTable.SETTLEMENTS:
          allData = await getAllData(
            `${settlementIndexName}`,
            totalSettlements.value,
            TransactionTypes.TransactionTable.SETTLEMENTS,
            10000,
          );
          break;
        case TransactionTypes.DisplayTable.FEES:
          allData = await getAllData(
            `${feesIndexName}`,
            totalFees.value,
            TransactionTypes.TransactionTable.FEES,
            10000,
          );
          break;
        default:
          throw new Error(
            `case for display index : ${displayIndex.value} not implemented`,
          );
      }
      setAllTransactionData(allData);
    }
  }

  async function fetchTransactionData(
    index: number,
    query: TransactionTypes.FilterQuery,
    filters: TransactionTypes.FilterCategory[],
  ) {
    if (!user.value || !user.value.partnerId || !user.value.token) {
      throw new Error('cannot retrieve data without user, partnerId and token');
    }
    const { partnerId, token } = user.value;
    switch (index) {
      case TransactionTypes.DisplayTable.PAYMENTS:
        const payments = await paymentsStore.getPaymentData(
          partnerId,
          token,
          query,
          filters,
        );
        return payments;
      case TransactionTypes.DisplayTable.COLLECTIONS:
        const collections = await collectionsStore.getCollectionData(
          partnerId,
          token,
          query,
          filters,
        );
        return collections;
      case TransactionTypes.DisplayTable.SETTLEMENTS:
        const settlements = await settlementStore.getSettlementData(
          partnerId,
          token,
          query,
          filters,
        );
        return settlements;
      case TransactionTypes.DisplayTable.FEES:
        const fees = await feesStore.getFeesData(
          partnerId,
          token,
          query,
          filters,
        );
        return fees;
      default:
        const paymentsData = await paymentsStore.getPaymentData(
          partnerId,
          token,
          query,
          filters,
        );
        return paymentsData;
    }
  }

  function sanitizeInput(input: string) {
    return input?.replace(/[^A-Fa-f0-9-]+/g, '') || '';
  }

  async function getTransactionData() {
    isLoading.value = true;
    if (!user.value || !user.value.partnerId || !user.value.token) {
      throw new Error('cannot retrieve data without user, partnerId and token');
    }

    transactionsQuery.idFilter = sanitizeInput(transactionsQuery.idFilter);
    const data = await fetchTransactionData(
      displayIndex.value,
      transactionsQuery,
      filterTransactions,
    );
    setDisplayData(data);

    switch (displayIndex.value) {
      case TransactionTypes.DisplayTable.PAYMENTS:
        // get payment data
        setDisplayHeaders(PaymentTypes.displayHeaders);
        setFilters(paymentFilters.value);
        displayCount.value = totalPayments.value;
        break;
      case TransactionTypes.DisplayTable.COLLECTIONS:
        // get collection data
        setDisplayHeaders(CollectionTypes.displayHeaders);
        setFilters(collectionFilters.value);
        displayCount.value = totalCollections.value;
        break;
      case TransactionTypes.DisplayTable.SETTLEMENTS:
        // get collection data
        setDisplayHeaders(SettlementTypes.DisplayHeadersSettlements);
        setFilters(settlementFilters.value);
        displayCount.value = totalSettlements.value;
        break;
      case TransactionTypes.DisplayTable.FEES:
        setDisplayHeaders(FeeTypes.displayHeaders);
        setFilters(feesFilters.value);
        displayCount.value = totalFees.value;
        break;
      default:
        displayIndex.value = 1;
        setFilters(paymentFilters.value);
        displayCount.value = totalPayments.value;
        break;
    }

    const filterActive = filterTransactions.some(
      category => category.filter !== '',
    );
    const dateFilterActive =
      transactionsQuery.range.startDate !== '' ||
      transactionsQuery.range.endDate !== '';

    if (
      displayData.length === 0 &&
      transactionsQuery.idFilter === '' &&
      !filterActive &&
      !dateFilterActive
    ) {
      noTransactionData.value = true;
      noDataForId.value = false;
      noDataForFilter.value = false;
      noTransactionDataForDateFilter.value = false;
    } else if (
      displayData.length === 0 &&
      transactionsQuery.idFilter !== '' &&
      !dateFilterActive
    ) {
      noTransactionData.value = false;
      noDataForId.value = true;
      noDataForFilter.value = false;
      noTransactionDataForDateFilter.value = false;
    } else if (
      displayData.length === 0 &&
      transactionsQuery.idFilter === '' &&
      filterActive &&
      !dateFilterActive
    ) {
      noTransactionData.value = false;
      noDataForId.value = false;
      noDataForFilter.value = true;
      noTransactionDataForDateFilter.value = false;
    } else if (displayData.length === 0 && dateFilterActive) {
      noTransactionDataForDateFilter.value = true;
      noTransactionData.value = false;
      noDataForId.value = false;
      noDataForFilter.value = false;
    } else {
      noTransactionData.value = false;
      noDataForId.value = false;
      noDataForFilter.value = false;
      noTransactionDataForDateFilter.value = false;
    }
    isLoading.value = false;
    await getAllTransactionData()
      .then(() => Promise.resolve())
      .catch(e => console.error('could not retrieve all transaction data', e));
  }

  return {
    allTransactionData,
    getTransactionData,
    setQueryIdSearchFilter,
    setStartAndEndDateSearchFilter,
    setQueryStartIndex,
    isLoading,
    noDataForId,
    transactionsQuery,
    resetTransactionsStores,
    noTransactionData,
    displayData,
    displayCount,
    displayIndex,
    filterTransactions,
    resetFilterSelection,
    noDataForFilter,
    displayHeaders,
    tabChange,
    fetchTransactionData,
    noTransactionDataForDateFilter,
    getAllTransactionData,
    user,
  };
});

export default useTransactionsStore;

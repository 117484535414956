<template>
  <div id="tabs-container" :class="customClass" ref="tabContainer">
    <div id="tab-headers">
      <ul>
        <!-- this shows all of the titles -->
        <li
          v-for="(tab, index) in tabs"
          :key="index"
          :class="activeTabIndex === index ? 'active' : ''"
          @click="changeTab(index, tab.title)"
          ref="tabHeaders"
        >
          {{ tab.title }}
        </li>
      </ul>
    </div>
    <!-- this is where the tabs go, in this slot -->
    <div id="active-tab">
      <slot></slot>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from 'vue';

export default defineComponent({
  name: 'Tabs',
  props: {
    customClass: [],
  },
  emits: ['tabChange'],
  setup(props, { emit }) {
    const tabContainer = ref<any>(null);
    const tabHeaders = ref<any>();
    const tabs = ref<any>();
    const activeTabIndex = ref(0);

    /* eslint-disable no-restricted-syntax */
    onMounted(() => {
      tabs.value = [...tabContainer.value.querySelectorAll('.tab')];
      for (const x of tabs.value) {
        if (x.classList.contains('active')) {
          activeTabIndex.value = tabs.value.indexOf(x);
        }
      }
    });

    const changeTab = (index: number, title: string) => {
      // Set activeTabIndex item to the index of the element clicked
      activeTabIndex.value = index;
      // Remove any active classes
      for (const x of [...tabs.value, ...tabHeaders.value]) {
        x.classList.remove('active');
      }
      // Add active classes where appropriate, to the active elements!
      tabs.value[activeTabIndex.value].classList.add('active');
      tabHeaders.value[activeTabIndex.value].classList.add('active');
      emit('tabChange', index, title);
    };

    return {
      changeTab,
      tabContainer,
      tabHeaders,
      tabs,
      activeTabIndex,
    };
  },
});
</script>

<style lang="scss">
@use '../../assets/scss/colors' as colors;

#tab-headers ul {
  margin: 0;
  padding: 0;
  display: flex;
  //border-bottom: 2px solid #ddd;
}

#tab-headers ul li {
  list-style: none;
  padding: 1rem 1.25rem;
  position: relative;
  cursor: pointer;
  font-family: 'Jost', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: colors.$brand-grey-600;
}

#tab-headers ul li.active {
  color: #000000;
}

#tab-headers ul li.active:after {
  content: '';
  position: absolute;
  bottom: -2px;
  left: 0;
  height: 2px;
  width: 100%;
  background: colors.$brand-secondary-teal-400;
}

#active-tab,
#tab-headers {
  width: 100%;
}

#active-tab {
  padding: 0.75rem;
}
</style>

<template>
  <div :class="`yc--steps--card`">
    <div :class="`yc--steps--card__modal`">
      <div :class="`yc--steps--card__title-bar`">
        <div>
          <button
            v-if="step > 1"
            :class="`yc--steps--card__button`"
            type="button"
            @click="$emit('close')"
          >
            <svg
              class="yc--steps--card__svg"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
            >
              <path
                d="M18.969 12A.98.98 0 0 1 18 13H8.406l3.281 3.313a.964.964 0 0 1 0 1.406A.97.97 0 0 1 11 18a.99.99 0 0 1-.719-.281l-5-5a.964.964 0 0 1 0-1.406l5-5a.964.964 0 0 1 1.406 0 .964.964 0 0 1 0 1.406L8.408 11H18c.531 0 .969.469.969 1Z"
                fill="#000"
              />
            </svg>
          </button>
        </div>
        <p :class="`yc--steps--card__top-right`">
          <slot name="step"></slot>
        </p>
      </div>
      <slot name="body"></slot>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'StepsCard',
  props: {
    step: {
      type: Number,
      default: 1,
    },
  },
});
</script>

<style lang="scss">
@use '../../assets/scss/include-media' as media;

.yc--steps--card {
  display: flex;
  justify-content: center;

  &__modal {
    position: relative;
    text-align: center;
    padding: 0;
    align-items: center;
    gap: 4px;

    @include media.media('>=tablet') {
      width: 456px;
      min-height: 130px;
      box-shadow: 0 22px 50px rgba(0, 0, 0, 0.08);
      border-radius: 16px;
      background: #ffffff;
    }
  }

  &__title-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px 0 16px;
  }

  &__button {
    margin: 0;
    padding: 0;
    cursor: pointer;
    border: none;
    background: transparent;
  }

  &__svg {
    width: 24px;
  }

  &__top-right {
    font-family: 'Jost', sans-serif;
    font-style: normal;
    font-size: 12px;
    line-height: 18px;
    color: #000000;
  }
}
</style>

<template>
  <div class="yc--input">
    <div id="container" class="yc--input__input-container">
      <input
        :id="label"
        :type="getInputType()"
        :name="name"
        v-model="input"
        :placeholder="placeholder"
        :class="{ 'yc--input__input': true, 'yc--input__error-border': error }"
        :required="required"
        :disabled="disabled"
        :readonly="readonly"
        :aria-disabled="disabled"
        :aria-readonly="readonly"
        :aria-required="required"
        @blur="$emit('blur', true)"
        @focus="$emit('focus', true)"
        @input="onInput"
      />
      <!--  -->
      <label
        :for="label"
        v-if="showLabel"
        :class="{
          'yc--input__label': true,
          'yc--input__transform': input !== '',
        }"
      >
        {{ label }}
      </label>
    </div>
    <button class="yc--input__hide-show" v-if="type === 'password'">
      <img
        v-show="!showPassword"
        src="../../assets/eye-slash.svg"
        alt="Show password"
        @click.prevent="togglePasswordVisibility"
      />
      <img
        v-show="showPassword"
        src="../../assets/eye.svg"
        alt="Hide password"
        @click.prevent="togglePasswordVisibility"
      />
    </button>
    <button class="yc--input__hide-show" v-if="type === 'edit'">
      <img src="../../assets/angle-down.svg" alt="Edit" />
    </button>
    <button class="yc--input__hide-show" v-if="type === 'wallet'">
      <img src="../../assets/Text.svg" alt="Wallet address" />
    </button>
    <div v-if="error">
      <span class="yc--input__error-text">
        {{ error }}
      </span>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, watch } from 'vue';

export default defineComponent({
  name: 'Input',
  props: {
    name: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      validator: (val: string) =>
        ['text', 'number', 'email', 'password', 'edit', 'wallet'].includes(val),
    },
    required: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: Function,
      default: () => true,
    },
    value: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    formatNumber: {
      type: Boolean,
      default: false,
    },
    showLabel: {
      type: Boolean,
      default: true,
    },
  },
  setup(props, { emit }) {
    const error = ref(undefined);
    const input = ref(props.value);
    const showPassword = ref(false);

    const togglePasswordVisibility = () => {
      showPassword.value = !showPassword.value;
    };

    const removeCharsFromNumber = (number: string) => number.replace(/,/g, '');

    function numberFormat(
      number: number,
      maximumFractionDigits = 2,
      minimumFractionDigits = 0,
    ) {
      if (!number) {
        return '0.00';
      }
      return new Intl.NumberFormat('en-US', {
        minimumFractionDigits,
        maximumFractionDigits,
      }).format(number);
    }

    const onInput = (event: any) => {
      const inputValue = event.target.value;
      const keyPressed = event.data;

      if (props.type === 'number' && event.target.value !== '') {
        const value = removeCharsFromNumber(inputValue);
        if (!Number.isNaN(Number(keyPressed))) {
          if (props.formatNumber) {
            event.target.value = numberFormat(Number(value));
          } else {
            event.target.value = Number(inputValue);
          }
        } else {
          const keyPosition = event.target.value.indexOf(keyPressed);
          event.target.value =
            event.target.value.slice(0, keyPosition) +
            event.target.value.slice(
              keyPosition + 1,
              event.target.value.length,
            );
          event.preventDefault();
          event.stopImmediatePropagation();
        }
      } else {
        event.target.value = inputValue;
      }
      emit('update:modelValue', event.target.value);
    };

    function getInputType() {
      if (
        props.type === 'number' ||
        (props.type === 'password' && showPassword.value)
      )
        return 'text';

      return props.type;
    }

    /* eslint-disable no-unused-expressions */
    watch(input, value => {
      const ruleError = props.rules(value || '');
      // Validate the input based on the rules prop
      ruleError === true
        ? (error.value = undefined)
        : (error.value = ruleError);

      // dont emit error until user inputs
      if (!value && ruleError === true) {
        error.value = undefined;
      }
    });

    return {
      error,
      input,
      showPassword,
      togglePasswordVisibility,
      getInputType,
      onInput,
    };
  },
});
</script>

<style lang="scss">
@use '../../assets/scss/colors' as colors;

.yc--input {
  position: relative;
  text-align: left;
  flex-direction: row;
  align-items: flex-start;
  background: #ffffff;
  font-family: 'Jost', sans-serif;

  &__input-container {
    position: relative;
  }

  &__input {
    width: 100%;
    height: 56px;
    box-sizing: border-box;
    background: transparent;
    border: 1px solid #bdbdbd;
    border-radius: 12px;
    transition: border 500ms;
    padding: 25px 16px 6px;
    font-weight: 500;
    color: #000000;
    font-size: 14px;
    line-height: 22px;
    font-family: 'Jost', sans-serif;
  }

  &__label {
    display: block;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 16px;
    font-weight: 500;
    color: #9e9e9e;
    font-size: 12px;
    transform-origin: left top;
    user-select: none;
    transition:
      transform 150ms cubic-bezier(0.4, 0, 0.2, 1),
      color 150ms cubic-bezier(0.4, 0, 0.2, 1),
      top 500ms;
  }

  &__input:focus {
    outline: none;
    border: 1px solid colors.$brand-secondary-purple;
  }

  &__input:focus + label {
    transform: translateY(-100%) scale(0.75);
  }

  &__transform {
    transform: translateY(-100%) scale(0.75);
  }

  &__error-text {
    width: 360px;
    height: 18px;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #b00020;
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
  }

  &__error-border {
    border: 1px solid #b00020 !important;
    border-radius: 12px;
  }

  &__hide-show {
    position: absolute;
    background: transparent;
    outline: none;
    right: 20px;
    border: none;
    top: 20px;
    cursor: pointer;
  }
}
</style>
